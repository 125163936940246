import React from 'react'
import { Text, View } from 'react-native'

export default function Icon({ style, accessibilityLabel, size, color, onPress, name }) {
    const text = FONT_MAP[name]
    return (
        <View style={style}>
            <Text
                accessibilityLabel={accessibilityLabel}
                style={{
                    fontFamily: 'alldone',
                    opacity: 1,
                    fontSize: size,
                    color: color,
                }}
                onPress={onPress}
            >
                {text}
            </Text>
        </View>
    )
}

const FONT_MAP = {
    fingerprint: '',
    'add-task': '',
    'settings-1': '',
    'linked-task': '',
    gmail: '',
    'loading-static': '',
    'icon-circle': '',
    'icon-circle-poject_color': '',
    'icon-note-version-history': '',
    cap: '',
    'bar-chart-2-Horizontal': '',
    crown: '',
    'milestone-2': '',
    'double-check': '',
    'check-small': '',
    'maximize-3': '',
    distribute: '',
    'back-link': '',
    'video-meeting': '',
    'add-video': '',
    'screen-recording': '',
    'check-thiker': '',
    'x-thicker': '',
    'video-in-progress': '',
    'six-dots-vertical': '',
    'multi-selection-selected': '',
    'promote-to-task': '',
    promote: '',
    'comments-thread': '',
    'square-Sub': '',
    'check-square-Sub': '',
    'clock-Sub': '',
    'square-checked-gray-Sub': '',
    timer: '',
    'trash-2-Sub': '',
    projects: '',
    'next-workflow': '',
    'new-window': '',
    'archive-minus': '',
    'archive-plus': '',
    'story-point-done': '',
    'story-point-open': '',
    'circle-details': '',
    assistant: '',
    issue: '',
    'square-checked': '',
    '1-inside-radio-button': '',
    '2-inside-radio-button': '',
    '3-inside-radio-button': '',
    '4-inside-radio-button': '',
    '5-inside-radio-button': '',
    '6-inside-radio-button': '',
    '7-inside-radio-button': '',
    '8-inside-radio-button': '',
    '9-inside-radio-button': '',
    '10-inside-radio-button': '',
    '11-inside-radio-button': '',
    '12-inside-radio-button': '',
    '13-inside-radio-button': '',
    '14-inside-radio-button': '',
    '15-inside-radio-button': '',
    '16-inside-radio-button': '',
    '17-inside-radio-button': '',
    '18-inside-radio-button': '',
    '19-inside-radio-button': '',
    '20-inside-radio-button': '',
    '21-inside-radio-button': '',
    '22-inside-radio-button': '',
    '23-inside-radio-button': '',
    '24-inside-radio-button': '',
    '25-inside-radio-button': '',
    '26-inside-radio-button': '',
    '27-inside-radio-button': '',
    '28-inside-radio-button': '',
    '29-inside-radio-button': '',
    '30-inside-radio-button': '',
    '31-inside-radio-button': '',
    feed: '',
    'square-checked-gray': '',
    'multi-selection': '',
    status: '',
    workflow: '',
    'folder-clasify': '',
    'folder-open': '',
    'story-point': '',
    'sticky-note': '',
    logo: '',
    'logo-name': '',
    0: '',
    1: '',
    2: '',
    3: '',
    5: '',
    8: '',
    13: '',
    21: '',
    activity: '',
    airplay: '',
    'alert-circle': '',
    'alert-octagon': '',
    'alert-triangle': '',
    'align-center': '',
    'align-justify': '',
    'align-left': '',
    'align-right': '',
    anchor: '',
    aperture: '',
    archive: '',
    'arrow-down': '',
    'arrow-down-circle': '',
    'arrow-down-left': '',
    'arrow-down-right': '',
    'arrow-down-symbol': '',
    'arrow-left': '',
    'arrow-left-circle': '',
    'arrow-left-symbol': '',
    'arrow-right': '',
    'arrow-right-circle': '',
    'arrow-right-symbol': '',
    'arrow-up': '',
    'arrow-up-circle': '',
    'arrow-up-left': '',
    'arrow-up-right': '',
    'arrow-up-symbol': '',
    'at-sign': '',
    award: '',
    'bar-chart': '',
    'bar-chart-2': '',
    'bar-chart-3': '',
    'bar-chart-4': '',
    battery: '',
    'battery-charging': '',
    bell: '',
    'bell-off': '',
    bluetooth: '',
    bold: '',
    book: '',
    bookmark: '',
    'book-open': '',
    box: '',
    briefcase: '',
    calendar: '',
    'calendar-observer': '',
    'calendar-up': '',
    camera: '',
    'camera-off': '',
    cast: '',
    check: '',
    'check-circle': '',
    'check-square': '',
    'chevron-down': '',
    'chevron-left': '',
    'chevron-right': '',
    'chevrons-down': '',
    'chevrons-left': '',
    'chevrons-right': '',
    'chevrons-up': '',
    'chevron-up': '',
    chrome: '',
    circle: '',
    'circle-poject_color': '',
    'clear-formatting': '',
    clipboard: '',
    clock: '',
    cloud: '',
    'cloud-drizzle': '',
    'cloud-lightning': '',
    'cloud-off': '',
    'cloud-rain': '',
    'cloud-snow': '',
    code: '',
    codepen: '',
    codesandbox: '',
    coffee: '',
    columns: '',
    command: '',
    compass: '',
    copy: '',
    'corner-down-left': '',
    'corner-down-right': '',
    'corner-left-down': '',
    'corner-left-up': '',
    'corner-right-down': '',
    'corner-right-up': '',
    'corner-up-left': '',
    'corner-up-right': '',
    'count-circle-0': '',
    'count-circle-1': '',
    'count-circle-2': '',
    'count-circle-3': '',
    'count-circle-5': '',
    'count-circle-8': '',
    'count-circle-13': '',
    'count-circle-21': '',
    'count-circle-15m': '',
    'count-circle-30m': '',
    'count-circle-1h': '',
    'count-circle-2h': '',
    'count-circle-4h': '',
    'count-circle-8h': '',
    'count-circle-16h': '',
    'count-circle-24h': '',
    'count-circle-1d': '',
    'count-circle-2d': '',
    'count-circle-3d': '',
    'count-circle-c': '',
    cpu: '',
    'credit-card': '',
    crop: '',
    crosshair: '',
    'cross-out-text': '',
    database: '',
    'decrease-ident': '',
    delete: '',
    disc: '',
    'dollar-sign': '',
    dot: '',
    download: '',
    'download-cloud': '',
    droplet: '',
    'droplet-off': '',
    dumbbell: '',
    ear: '',
    edit: '',
    'edit-2': '',
    'edit-3': '',
    'edit-4': '',
    'edit-5': '',
    'edit-6': '',
    'envelope-open': '',
    'external-link': '',
    eye: '',
    'eye-off': '',
    facebook: '',
    'fast-forward': '',
    feather: '',
    figma: '',
    file: '',
    'file-minus': '',
    'file-plus': '',
    'file-text': '',
    film: '',
    filter: '',
    flag: '',
    folder: '',
    'folder-minus': '',
    'folder-plus': '',
    framer: '',
    frown: '',
    gift: '',
    'git-branch': '',
    'git-commit': '',
    github: '',
    gitlab: '',
    'git-merge': '',
    'git-pull-request': '',
    globe: '',
    grid: '',
    'hard-drive': '',
    hash: '',
    headphones: '',
    heart: '',
    'help-circle': '',
    hexagon: '',
    highlight: '',
    'count-0': '',
    'count-1': '',
    'count-2': '',
    'count-3': '',
    'count-5': '',
    'count-8': '',
    'count-13': '',
    'count-21': '',
    'count-15m': '',
    'count-30m': '',
    'count-1h': '',
    'count-2h': '',
    'count-4h': '',
    'count-8h': '',
    'count-16h': '',
    'count-24h': '',
    'count-1d': '',
    'count-2d': '',
    'count-3d': '',
    'count-c': '',
    home: '',
    image: '',
    inbox: '',
    'increase-ident': '',
    info: '',
    instagram: '',
    italic: '',
    key: '',
    kick: '',
    layers: '',
    layout: '',
    language: '',
    'life-buoy': '',
    'line-spacing': '',
    link: '',
    'link-2': '',
    linkedin: '',
    list: '',
    'list-bulleted': '',
    'list-numbered': '',
    loader: '',
    lock: '',
    'log-in': '',
    'log-out': '',
    mail: '',
    map: '',
    'map-pin': '',
    maximize: '',
    'maximize-2': '',
    meh: '',
    menu: '',
    'message-circle': '',
    'message-square': '',
    'add-message-circle': '',
    'previous-message-circle': '',
    mic: '',
    'mic-off': '',
    minimize: '',
    'minimize-2': '',
    minus: '',
    'minus-circle': '',
    'minus-square': '',
    monitor: '',
    moon: '',
    'more-horizontal': '',
    'more-vertical': '',
    'more-vertical-smaller': '',
    'mouse-pointer': '',
    move: '',
    music: '',
    navigation: '',
    'navigation-2': '',
    'notification-mail': '',
    octagon: '',
    'option-key': '',
    palette: '',
    package: '',
    paintbrush: '',
    paperclip: '',
    pause: '',
    'pause-circle': '',
    'pen-tool': '',
    percent: '',
    phone: '',
    'phone-call': '',
    'phone-forwarded': '',
    'phone-incoming': '',
    'phone-missed': '',
    'phone-off': '',
    'phone-outgoing': '',
    'pie-chart': '',
    pill: '',
    play: '',
    'play-circle': '',
    plus: '',
    'plus-circle': '',
    'plus-square': '',
    pocket: '',
    power: '',
    printer: '',
    radio: '',
    'refresh-ccw': '',
    'refresh-cw': '',
    repeat: '',
    rewind: '',
    'rotate-ccw': '',
    'rotate-cw': '',
    rss: '',
    save: '',
    scissors: '',
    search: '',
    send: '',
    'send-1': '',
    spacebar: '',
    server: '',
    settings: '',
    'several-file-text': '',
    share: '',
    'share-2': '',
    shield: '',
    'shield-off': '',
    'shift-key': '',
    shoe: '',
    'shopping-bag': '',
    'shopping-cart': '',
    shuffle: '',
    sidebar: '',
    'skip-back': '',
    'skip-forward': '',
    slack: '',
    'slack-2': '',
    slash: '',
    sliders: '',
    smartphone: '',
    smile: '',
    'sort-arrow': '',
    'sort-list': '',
    speaker: '',
    spinner: '',
    square: '',
    star: '',
    'stop-circle': '',
    summation: '',
    sun: '',
    sunrise: '',
    sunset: '',
    tablet: '',
    tag: '',
    target: '',
    terminal: '',
    'text-color': '',
    thermometer: '',
    'thumbs-down': '',
    'thumbs-up': '',
    'thumbs-up-checked': '',
    timestamp: '',
    'toggle-left': '',
    'toggle-right': '',
    tool: '',
    tooth: '',
    trash: '',
    'trash-2': '',
    trello: '',
    'trending-down': '',
    'trending-up': '',
    triangle: '',
    truck: '',
    tv: '',
    twitch: '',
    twitter: '',
    type: '',
    umbrella: '',
    underline: '',
    unlock: '',
    unlink: '',
    upload: '',
    'upload-cloud': '',
    user: '',
    'user-aster': '',
    'user-check': '',
    'user-minus': '',
    'user-plus': '',
    users: '',
    'user-x': '',
    video: '',
    'video-off': '',
    voicemail: '',
    volume: '',
    'volume-1': '',
    'volume-2': '',
    'volume-x': '',
    watch: '',
    wifi: '',
    'wifi-off': '',
    workstream: '',
    wind: '',
    x: '',
    'x-circle': '',
    'x-octagon': '',
    'x-square': '',
    youtube: '',
    zap: '',
    'zap-off': '',
    'zoom-in': '',
    'zoom-out': '',
}
