import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import AllProjectsEmptyInbox from '../../TaskListView/OpenTasksView/AllProjectsEmptyInbox'
import MyDayDateLine from './MyDayDateLine'
import MyDaySelectedTasks from './MyDaySelectedTasks'
import MoreTasksLine from './MoreTasksLine'
import MyDayOtherTasks from './MyDayOtherTasks'

export default function MyDayTasks() {
    const selectedTasksAmount = useSelector(state => state.myDaySelectedTasks.length)
    const tasksLoaded = useSelector(state => state.myDayAllTodayTasks.loaded)
    const myDayOtherTasksAmount = useSelector(state => state.myDayOtherTasks.length)
    const myDayShowAllTasks = useSelector(state => state.myDayShowAllTasks)

    return (
        <View style={{ marginTop: 16, marginBottom: 32 }}>
            {tasksLoaded &&
                (selectedTasksAmount > 0 ? (
                    <>
                        <MyDayDateLine />
                        <MyDaySelectedTasks />
                        {myDayOtherTasksAmount > 0 && <MoreTasksLine />}
                        {myDayShowAllTasks && (
                            <>
                                <MyDayOtherTasks />
                                <MoreTasksLine />
                            </>
                        )}
                    </>
                ) : (
                    <AllProjectsEmptyInbox />
                ))}
        </View>
    )
}
