import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import styles from '../styles/global'
import { translate } from '../../i18n/TranslationService'

export default function MyDayHeader() {
    return (
        <View style={localStyles.container}>
            <Text style={localStyles.title}>{translate('My day')}</Text>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        height: 80,
        maxHeight: 80,
        paddingTop: 40,
        paddingBottom: 8,
    },
    title: {
        ...styles.title5,
    },
})
