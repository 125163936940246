import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import v4 from 'uuid/v4'

import { watchObservedTasks, watchTasksToAttend } from '../../../utils/backends/Tasks/myDayTasks'
import { unwatch } from '../../../utils/backends/firestore'
import { DEFAULT_WORKSTREAM_ID } from '../../Workstreams/WorkstreamHelper'
import MyDayTasksWorkstreamLoaders from './MyDayTasksWorkstreamLoaders'
import { clearMyDayAllTodayTasksInProject } from '../../../redux/actions'

export default function MyDayTasksProjectLoaders({ projectId }) {
    const dispatch = useDispatch()
    const loggedUserId = useSelector(state => state.loggedUser.uid)
    const workstreamsIds = useSelector(state => state.loggedUser.workstreams[projectId])

    const workstreamsToLoadIds = workstreamsIds ? [DEFAULT_WORKSTREAM_ID, ...workstreamsIds] : [DEFAULT_WORKSTREAM_ID]

    useEffect(() => {
        const watcherKey = v4()
        watchTasksToAttend(projectId, loggedUserId, watcherKey)
        return () => {
            unwatch(watcherKey)
        }
    }, [projectId, loggedUserId])

    useEffect(() => {
        const watcherKey = v4()
        watchObservedTasks(projectId, loggedUserId, watcherKey)
        return () => {
            unwatch(watcherKey)
        }
    }, [projectId, loggedUserId])

    useEffect(() => {
        return () => {
            dispatch(clearMyDayAllTodayTasksInProject(projectId))
        }
    }, [])

    return (
        <View>
            {workstreamsToLoadIds.map(workstreamId => {
                return (
                    <MyDayTasksWorkstreamLoaders key={workstreamId} projectId={projectId} workstreamId={workstreamId} />
                )
            })}
        </View>
    )
}
