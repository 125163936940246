import React, { useState } from 'react'
import Popover from 'react-tiny-popover'
import { useSelector } from 'react-redux'
import TaskParentGoalModal from './TaskParentGoalModal'
import GoalTagIcon from '../../../Tags/GoalTagIcon'
import { exitsOpenModals, TASK_PARENT_GOAL_MODAL_ID } from '../../../ModalsManager/modalsManager'

export default function WrapperTaskParentGoalModal({ projectId, activeGoal, dateFormated }) {
    const mobile = useSelector(state => state.smallScreenNavigation)
    const [isOpen, setIsOpen] = useState(false)

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        if (!exitsOpenModals([TASK_PARENT_GOAL_MODAL_ID])) {
            setIsOpen(false)
        }
    }

    return (
        <Popover
            content={
                <TaskParentGoalModal
                    key={isOpen}
                    activeGoal={activeGoal}
                    projectId={projectId}
                    closeModal={closeModal}
                    delalyPrivacyModalClose={true}
                    fromAddTaskSection={true}
                    dateFormated={dateFormated}
                />
            }
            isOpen={isOpen}
            position={['bottom', 'left', 'right', 'top']}
            padding={4}
            align={'end'}
            onClickOutside={closeModal}
            contentLocation={mobile ? null : undefined}
        >
            <GoalTagIcon onPress={openModal} disabled={isOpen} highlightIcon={!!activeGoal} />
        </Popover>
    )
}
