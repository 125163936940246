import React from 'react'
import { useSelector } from 'react-redux'

import MyDayTasksList from './MyDayTasksList'

export default function MyDaySelectedTasks() {
    const myDaySelectedTasks = useSelector(state => state.myDaySelectedTasks)

    return <MyDayTasksList tasks={myDaySelectedTasks} />
}
