import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import styles, { colors, windowTagStyle } from '../styles/global'
import URLTrigger from '../../URLSystem/URLTrigger'
import NavigationService from '../../utils/NavigationService'
import { useSelector } from 'react-redux'
import ColoredCircleSmall from '../SidebarMenu/ProjectFolding/ProjectItem/ColoredCircleSmall'
import ProjectHelper from '../SettingsView/ProjectsSettings/ProjectHelper'

export default function ProjectTag({ projectId, project, style, disabled, isMobile, path }) {
    const loggedUserId = useSelector(state => state.loggedUser.uid)

    const finalProject = project || ProjectHelper.getProjectById(projectId)

    const onPress = () => {
        const finalPath = path || `/projects/${finalProject.id}/user/${loggedUserId}/tasks/open`
        URLTrigger.processUrl(NavigationService, finalPath)
    }

    return finalProject ? (
        <TouchableOpacity disabled={disabled} onPress={onPress}>
            <View style={[localStyles.container, isMobile && localStyles.containerMobile, style]}>
                <ColoredCircleSmall
                    size={12}
                    color={finalProject.color}
                    isGuide={!!finalProject.parentTemplateId}
                    containerStyle={{ marginHorizontal: 6 }}
                    lineHeight={20}
                    projectId={finalProject.id}
                />
                {!isMobile && (
                    <Text style={[styles.subtitle2, localStyles.text, windowTagStyle()]}>{finalProject.name}</Text>
                )}
            </View>
        </TouchableOpacity>
    ) : null
}

const localStyles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: colors.Gray300,
        borderRadius: 100,
        alignItems: 'center',
        justifyContent: 'center',
        height: 24,
    },
    containerMobile: {
        width: 24,
        height: 24,
    },
    text: {
        color: colors.Text03,
        marginVertical: 1,
        marginRight: 10,
        marginLeft: 2,
    },
})
