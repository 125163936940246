import React from 'react'
import { useSelector } from 'react-redux'

import MyDayTasksList from './MyDayTasksList'

export default function MyDayOtherTasks() {
    const myDayOtherTasks = useSelector(state => state.myDayOtherTasks)

    return <MyDayTasksList tasks={myDayOtherTasks} />
}
