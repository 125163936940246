export const FEED_PUBLIC_FOR_ALL = 0

export const FOLLOWED_TAB = 0
export const ALL_TAB = 1
export const BOTH_TABS = 2

export const FEED_USER_OBJECT_TYPE = 'user'
export const FEED_CONTACT_OBJECT_TYPE = 'contact'
export const FEED_PROJECT_OBJECT_TYPE = 'project'
export const FEED_TASK_OBJECT_TYPE = 'task'
export const FEED_NOTE_OBJECT_TYPE = 'note'
export const FEED_GOAL_OBJECT_TYPE = 'goal'
export const FEED_MILESTONE_OBJECT_TYPE = 'milestone'
export const FEED_CHAT_OBJECT_TYPE = 'chat'
export const FEED_WORKSTREAM_OBJECT_TYPE = 'workstream'
export const FEED_OBJECT_NOTE_OBJECT_TYPE = 'objectNote'
export const FEED_SKILL_OBJECT_TYPE = 'skill'
export const DEFAULT_SKILL_PRIVACY_OBJECT_TYPE = 'defaultSkill'
export const FEED_ASSISTANT_OBJECT_TYPE = 'assistant'

export const FEED_TASK_CREATED = 9
export const FEED_TASK_TO_ANOTHER_USER = 10
export const FEED_TASK_DELETED = 11
export const FEED_TASK_TITLE_CHANGED = 12
export const FEED_TASK_DUE_DATE_CHANGED = 13
export const FEED_TASK_ASSIGNEE_CHANGED = 14
export const FEED_TASK_ASSIGNEE_ESTIMATION_CHANGED = 15
export const FEED_TASK_RECURRENCE_CHANGED = 16
export const FEED_TASK_PROJECT_CHANGED_TO = 17
export const FEED_TASK_PROJECT_CHANGED_FROM = 18
export const FEED_TASK_PRIVACY_CHANGED = 19
export const FEED_TASK_HIGHLIGHTED_CHANGED = 20
export const FEED_TASK_MOVED_IN_WORKFLOW = 21
export const FEED_TASK_REVIEWER_ESTIMATION_CHANGED = 22
export const FEED_TASK_CHECKED_DONE = 23
export const FEED_TASK_UNCHECKED_DONE = 231
export const FEED_TASK_SUBTASK_PROMOTED = 24
export const FEED_TASK_GIVE_KARMA = 26
export const FEED_TASK_FOLLOWED = 27
export const FEED_TASK_UNFOLLOWED = 28
export const FEED_TASK_BACKLINK = 566
export const FEED_TASK_OBSERVED = 575
export const FEED_TASK_UNOBSERVED = 576
export const FEED_TASK_DESCRIPTION = 577
export const FEED_TASK_PARENT_GOAL = 578
export const FEED_TASK_FOCUS_CHANGED = 'FEED_TASK_FOCUS_CHANGED'
export const FEED_TASK_ASSISTANT_CHANGED = 'FEED_TASK_ASSISTANT_CHANGED'
export const FEED_TASK_OBSERVER_ESTIMATION_CHANGED = 'FEED_TASK_OBSERVER_ESTIMATION_CHANGED'

export const FEED_PROJECT_CREATED = 29
export const FEED_PROJECT_PRIVACY_CHANGED = 30
export const FEED_PROJECT_COLOR_CHANGED = 31
export const FEED_PROJECT_TITLE_CHANGED = 32
export const FEED_PROJECT_DESCRIPTION_CHANGED = 321
export const FEED_PROJECT_ARCHIVED_UNARCHIVED = 33
export const FEED_PROJECT_SENT_INVITATION = 34
export const FEED_PROJECT_KICKED_MEMBER = 35
export const FEED_PROJECT_DECLINED_INVITATION = 36
export const FEED_PROJECT_GIVE_KARMA = 38
export const FEED_PROJECT_FOLLOWED = 39
export const FEED_PROJECT_UNFOLLOWED = 40
export const FEED_PROJECT_BACKLINK = 571
export const FEED_PROJECT_GUIDE_CHANGED = 'FEED_PROJECT_GUIDE_CHANGED'
export const FEED_PROJECT_ESTIMATION_TYPE_CHANGED = 'FEED_PROJECT_ESTIMATION_TYPE_CHANGED'
export const FEED_PROJECT_ASSISTANT_CHANGED = 'FEED_PROJECT_ASSISTANT_CHANGED'

export const FEED_CONTACT_ADDED = 41
export const FEED_CONTACT_PICTURE_CHANGED = 42
export const FEED_CONTACT_EMAIL_CHANGED = 43
export const FEED_CONTACT_PHONE_NUMBER_CHANGED = 44
export const FEED_CONTACT_PRIVACY_CHANGED = 45
export const FEED_CONTACT_ROLE_CHANGED = 46
export const FEED_CONTACT_NAME_CHANGED = 47
export const FEED_CONTACT_COMPANY_CHANGED = 48
export const FEED_CONTACT_DELETED = 49
export const FEED_CONTACT_GIVE_KARMA = 51
export const FEED_CONTACT_FOLLOWED = 52
export const FEED_CONTACT_UNFOLLOWED = 53
export const FEED_CONTACT_DESCRIPTION_CHANGED = 531
export const FEED_CONTACT_BACKLINK = 568
export const FEED_CONTACT_HIGHLIGHT_CHANGED = 573
export const FEED_CONTACT_ASSISTANT_CHANGED = 573

export const FEED_USER_JOINED = 54
export const FEED_USER_ROLE_CHANGED = 55
export const FEED_USER_WORKFLOW_ADDED = 562
export const FEED_USER_WORKFLOW_REMOVE = 563
export const FEED_USER_WORKFLOW_CHANGED = 564
export const FEED_USER_WORKFLOW_CHANGED_DESC = 565
export const FEED_USER_COMPANY_CHANGED = 56
export const FEED_USER_GIVE_KARMA = 58
export const FEED_USER_FOLLOWED = 59
export const FEED_USER_UNFOLLOWED = 60
export const FEED_USER_FOLLOWING_ALL_MEMBERS = 61
export const FEED_USER_ALL_MEMBERS_FOLLOWING = 62
export const FEED_USER_DESCRIPTION_CHANGED = 561
export const FEED_USER_BACKLINK = 567
export const FEED_USER_HIGHLIGHT_CHANGED = 572
export const FEED_USER_PRIVACY_CHANGED = 574
export const FEED_USER_ASSISTANT_CHANGED = 574

export const FEED_NOTE_CREATED = 63
export const FEED_NOTE_FOLLOWED = 65
export const FEED_NOTE_UNFOLLOWED = 66
export const FEED_NOTE_GIVE_KARMA = 67
export const FEED_NOTE_TITLE_CHANGED = 68
export const FEED_NOTE_HIGHLIGHTED_CHANGED = 69
export const FEED_NOTE_PRIVACY_CHANGED = 70
export const FEED_NOTE_OWNER_CHANGED = 71
export const FEED_NOTE_DELETED = 72
export const FEED_NOTE_PROJECT_CHANGED_TO = 73
export const FEED_NOTE_PROJECT_CHANGED_FROM = 74
export const FEED_NOTE_STICKY = 75
export const FEED_NOTE_EDITING = 76
export const FEED_NOTE_BACKLINK = 570
export const FEED_NOTE_UPDATED = 'FEED_NOTE_UPDATED'
export const FEED_NOTE_ASSISTANT_CHANGED = 'FEED_NOTE_ASSISTANT_CHANGED'

export const FEED_GOAL_CREATED = 80
export const FEED_GOAL_TITLE_CHANGED = 81
export const FEED_GOAL_DELETED = 82
export const FEED_GOAL_MILESTONE_CHANGED = 83
export const FEED_GOAL_ASSIGNEES_CHANGED = 84
export const FEED_GOAL_PROJECT_CHANGED = 85
export const FEED_GOAL_PROGRESS_CHANGED = 86
export const FEED_GOAL_FOLLOWED = 88
export const FEED_GOAL_UNFOLLOWED = 89
export const FEED_GOAL_BACKLINK = 90
export const FEED_GOAL_HIGHLIGHTED_CHANGED = 91
export const FEED_GOAL_CAPACITY_CHANGED = 92
export const FEED_GOAL_DESCRIPTION_CHANGED = 93
export const FEED_GOAL_PRIVACY_CHANGED = 95
export const FEED_GOAL_ASSISTANT_CHANGED = 'FEED_GOAL_ASSISTANT_CHANGED'

export const FEED_SKILL_CREATED = 'SKILL_CREATED'
export const FEED_SKILL_DELETED = 'SKILL_DELETED'
export const FEED_SKILL_TITLE_CHANGED = 'SKILL_TITLE_CHANGED'
export const FEED_SKILL_PRIVACY_CHANGED = 'SKILL_PRIVACY_CHANGED'
export const FEED_SKILL_CHANGES_POINTS = 'FEED_SKILL_CHANGES_POINTS'
export const FEED_SKILL_DESCRIPTION_CHANGED = 'SKILL_DESCRIPTION_CHANGED'
export const FEED_SKILL_HIGHLIGHTED_CHANGED = 'SKILL_HIGHLIGHTED_CHANGED'
export const FEED_SKILL_PROJECT_CHANGED = 'SKILL_PROJECT_CHANGED'
export const FEED_SKILL_FOLLOWED = 'SKILL_FOLLOWED'
export const FEED_SKILL_UNFOLLOWED = 'SKILL_UNFOLLOWED'
export const FEED_SKILL_BACKLINK = 'SKILL_BACKLINK'
export const FEED_SKILL_COMPLETION_CHANGED = 'FEED_SKILL_COMPLETION_CHANGED'
export const FEED_SKILL_ASSISTANT_CHANGED = 'FEED_SKILL_ASSISTANT_CHANGED'

export const FEED_ASSISTANT_CREATED = 'FEED_ASSISTANT_CREATED'
export const FEED_ASSISTANT_DELETED = 'FEED_ASSISTANT_DELETED'
export const FEED_ASSISTANT_NAME_CHANGED = 'FEED_ASSISTANT_NAME_CHANGED'
export const FEED_ASSISTANT_DESCRIPTION_CHANGED = 'FEED_ASSISTANT_DESCRIPTION_CHANGED'
export const FEED_ASSISTANT_BACKLINK = 'FEED_ASSISTANT_BACKLINK'
export const FEED_ASSISTANT_FOLLOWED = 'FEED_ASSISTANT_FOLLOWED'
export const FEED_ASSISTANT_UNFOLLOWED = 'FEED_ASSISTANT_UNFOLLOWED'
export const FEED_ASSISTANT_PICTURE_CHANGED = 'FEED_ASSISTANT_PICTURE_CHANGED'
export const FEED_ASSISTANT_TYPE_CHANGED = 'FEED_ASSISTANT_TYPE_CHANGED'
export const FEED_ASSISTANT_INSTRUCTIONS_CHANGED = 'FEED_ASSISTANT_INSTRUCTIONS_CHANGED'
export const FEED_ASSISTANT_MODEL_CHANGED = 'FEED_ASSISTANT_MODEL_CHANGED'
export const FEED_ASSISTANT_TEMPERATURE_CHANGED = 'FEED_ASSISTANT_TEMPERATURE_CHANGED'

export const FEED_ASSISTANT_TYPES = [
    FEED_ASSISTANT_CREATED,
    FEED_ASSISTANT_DELETED,
    FEED_ASSISTANT_NAME_CHANGED,
    FEED_ASSISTANT_DESCRIPTION_CHANGED,
    FEED_ASSISTANT_BACKLINK,
    FEED_ASSISTANT_FOLLOWED,
    FEED_ASSISTANT_UNFOLLOWED,
    FEED_ASSISTANT_PICTURE_CHANGED,
    FEED_ASSISTANT_TYPE_CHANGED,
    FEED_ASSISTANT_INSTRUCTIONS_CHANGED,
    FEED_ASSISTANT_MODEL_CHANGED,
    FEED_ASSISTANT_TEMPERATURE_CHANGED,
]

export const FEED_SKILL_TYPES = [
    FEED_SKILL_CREATED,
    FEED_SKILL_DELETED,
    FEED_SKILL_TITLE_CHANGED,
    FEED_SKILL_PRIVACY_CHANGED,
    FEED_SKILL_CHANGES_POINTS,
    FEED_SKILL_DESCRIPTION_CHANGED,
    FEED_SKILL_HIGHLIGHTED_CHANGED,
    FEED_SKILL_PROJECT_CHANGED,
    FEED_SKILL_FOLLOWED,
    FEED_SKILL_UNFOLLOWED,
    FEED_SKILL_BACKLINK,
    FEED_SKILL_COMPLETION_CHANGED,
    FEED_SKILL_ASSISTANT_CHANGED,
]

export const FEED_GOAL_TYPES = [
    FEED_GOAL_CREATED,
    FEED_GOAL_TITLE_CHANGED,
    FEED_GOAL_DELETED,
    FEED_GOAL_MILESTONE_CHANGED,
    FEED_GOAL_ASSIGNEES_CHANGED,
    FEED_GOAL_PROJECT_CHANGED,
    FEED_GOAL_PROGRESS_CHANGED,
    FEED_GOAL_FOLLOWED,
    FEED_GOAL_UNFOLLOWED,
    FEED_GOAL_BACKLINK,
    FEED_GOAL_HIGHLIGHTED_CHANGED,
    FEED_GOAL_CAPACITY_CHANGED,
    FEED_GOAL_DESCRIPTION_CHANGED,
    FEED_GOAL_PRIVACY_CHANGED,
    FEED_GOAL_ASSISTANT_CHANGED,
]

export const FEED_NOTE_TYPES = [
    FEED_NOTE_CREATED,
    FEED_NOTE_FOLLOWED,
    FEED_NOTE_UNFOLLOWED,
    FEED_NOTE_GIVE_KARMA,
    FEED_NOTE_TITLE_CHANGED,
    FEED_NOTE_HIGHLIGHTED_CHANGED,
    FEED_NOTE_PRIVACY_CHANGED,
    FEED_NOTE_OWNER_CHANGED,
    FEED_NOTE_DELETED,
    FEED_NOTE_PROJECT_CHANGED_TO,
    FEED_NOTE_PROJECT_CHANGED_FROM,
    FEED_NOTE_STICKY,
    FEED_NOTE_EDITING,
    FEED_NOTE_BACKLINK,
    FEED_NOTE_UPDATED,
    FEED_NOTE_ASSISTANT_CHANGED,
]

export const FEED_TASK_TYPES = [
    FEED_TASK_CREATED,
    FEED_TASK_TO_ANOTHER_USER,
    FEED_TASK_DELETED,
    FEED_TASK_TITLE_CHANGED,
    FEED_TASK_DUE_DATE_CHANGED,
    FEED_TASK_ASSIGNEE_CHANGED,
    FEED_TASK_ASSIGNEE_ESTIMATION_CHANGED,
    FEED_TASK_RECURRENCE_CHANGED,
    FEED_TASK_PROJECT_CHANGED_TO,
    FEED_TASK_PROJECT_CHANGED_FROM,
    FEED_TASK_PRIVACY_CHANGED,
    FEED_TASK_HIGHLIGHTED_CHANGED,
    FEED_TASK_MOVED_IN_WORKFLOW,
    FEED_TASK_REVIEWER_ESTIMATION_CHANGED,
    FEED_TASK_CHECKED_DONE,
    FEED_TASK_UNCHECKED_DONE,
    FEED_TASK_SUBTASK_PROMOTED,
    FEED_TASK_GIVE_KARMA,
    FEED_TASK_FOLLOWED,
    FEED_TASK_UNFOLLOWED,
    FEED_TASK_BACKLINK,
    FEED_TASK_DESCRIPTION,
    FEED_TASK_PARENT_GOAL,
    FEED_TASK_FOCUS_CHANGED,
    FEED_TASK_ASSISTANT_CHANGED,
    FEED_TASK_OBSERVER_ESTIMATION_CHANGED,
]

export const FEED_PROJECT_TYPES = [
    FEED_PROJECT_CREATED,
    FEED_PROJECT_PRIVACY_CHANGED,
    FEED_PROJECT_COLOR_CHANGED,
    FEED_PROJECT_TITLE_CHANGED,
    FEED_PROJECT_DESCRIPTION_CHANGED,
    FEED_PROJECT_ARCHIVED_UNARCHIVED,
    FEED_PROJECT_SENT_INVITATION,
    FEED_PROJECT_KICKED_MEMBER,
    FEED_PROJECT_DECLINED_INVITATION,
    FEED_PROJECT_GIVE_KARMA,
    FEED_PROJECT_FOLLOWED,
    FEED_PROJECT_UNFOLLOWED,
    FEED_PROJECT_GUIDE_CHANGED,
    FEED_PROJECT_ESTIMATION_TYPE_CHANGED,
    FEED_PROJECT_ASSISTANT_CHANGED,
]

export const FEED_CONTACT_TYPES = [
    FEED_CONTACT_ADDED,
    FEED_CONTACT_PICTURE_CHANGED,
    FEED_CONTACT_EMAIL_CHANGED,
    FEED_CONTACT_PHONE_NUMBER_CHANGED,
    FEED_CONTACT_PRIVACY_CHANGED,
    FEED_CONTACT_ROLE_CHANGED,
    FEED_CONTACT_NAME_CHANGED,
    FEED_CONTACT_COMPANY_CHANGED,
    FEED_CONTACT_DELETED,
    FEED_CONTACT_GIVE_KARMA,
    FEED_CONTACT_FOLLOWED,
    FEED_CONTACT_UNFOLLOWED,
    FEED_CONTACT_DESCRIPTION_CHANGED,
    FEED_CONTACT_BACKLINK,
    FEED_CONTACT_ASSISTANT_CHANGED,
    FEED_CONTACT_HIGHLIGHT_CHANGED,
]

export const FEED_USER_TYPES = [
    FEED_USER_JOINED,
    FEED_USER_ROLE_CHANGED,
    FEED_USER_WORKFLOW_ADDED,
    FEED_USER_WORKFLOW_CHANGED,
    FEED_USER_WORKFLOW_REMOVE,
    FEED_USER_WORKFLOW_CHANGED_DESC,
    FEED_USER_COMPANY_CHANGED,
    FEED_USER_GIVE_KARMA,
    FEED_USER_FOLLOWED,
    FEED_USER_UNFOLLOWED,
    FEED_USER_FOLLOWING_ALL_MEMBERS,
    FEED_USER_ALL_MEMBERS_FOLLOWING,
    FEED_USER_DESCRIPTION_CHANGED,
    FEED_USER_BACKLINK,
    FEED_USER_ASSISTANT_CHANGED,
]

export const FEEDS_TYPES_TO_PARSE = [
    FEED_TASK_TITLE_CHANGED,
    FEED_TASK_DESCRIPTION,
    FEED_PROJECT_TITLE_CHANGED,
    FEED_PROJECT_DESCRIPTION_CHANGED,
    FEED_CONTACT_NAME_CHANGED,
    FEED_CONTACT_DESCRIPTION_CHANGED,
    FEED_USER_DESCRIPTION_CHANGED,
    FEED_NOTE_TITLE_CHANGED,
    FEED_GOAL_TITLE_CHANGED,
    FEED_GOAL_DESCRIPTION_CHANGED,
    FEED_SKILL_TITLE_CHANGED,
    FEED_SKILL_DESCRIPTION_CHANGED,
]
