import React from 'react'
import { StyleSheet } from 'react-native'

import styles from '../../styles/global'
import Button from '../../UIControls/Button'
import AssistantAvatar from '../../AdminPanel/Assistants/AssistantAvatar'

export default function AssistantDataButton({ openModal, assistant, disabled }) {
    const { photoURL50, displayName } = assistant

    return (
        <Button
            ref={ref => (this.botBtnRef = ref)}
            type={'ghost'}
            noBorder={true}
            onPress={openModal}
            customIcon={<AssistantAvatar photoURL={photoURL50} assistantId={assistant.uid} size={24} />}
            title={displayName}
            titleStyle={localStyles.text}
            disabled={disabled}
        />
    )
}

const localStyles = StyleSheet.create({
    text: {
        ...styles.title7,
        paddingVertical: 7,
        letterSpacing: 0,
    },
})
