import React, { useEffect, useState } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import TaskSummarizeTags from '../Tags/TaskSummarizeTags'
import { compact } from 'lodash'
import { useSelector } from 'react-redux'

const TaskItemTags = ({ children, isSubtask, setTagsExpandedHeight, amountTags }) => {
    const tablet = useSelector(state => state.isMiddleScreen)
    const mobile = useSelector(state => state.smallScreenNavigation)
    const mobileCollapsed = useSelector(state => state.smallScreenNavSidebarCollapsed)
    const [visible, setVisible] = useState(false)
    const finalAmountTags = amountTags >= 0 ? amountTags : compact(children).length

    const isMobile = mobile || mobileCollapsed
    const needSummarize = finalAmountTags > 5 || (tablet && finalAmountTags > 3) || (isMobile && finalAmountTags > 2)

    const toggleVisibleTags = e => {
        e.preventDefault()
        e.stopPropagation()
        setTagsExpanded(!visible)
        setVisible(!visible)
    }

    const setTagsExpanded = isVisible => {
        if (isVisible && needSummarize) {
            setTagsExpandedHeight(isSubtask ? 32 : 36)
        } else {
            setTagsExpandedHeight(0)
        }
    }

    useEffect(() => {
        setTagsExpanded(visible)
    }, [tablet, isMobile])

    return (
        <>
            {needSummarize && (
                <View
                    style={[
                        localStyles.container,
                        visible && (isSubtask ? localStyles.containerExpandSubTasks : localStyles.containerExpand),
                    ]}
                >
                    {finalAmountTags > 0 && (
                        <TaskSummarizeTags amountTags={finalAmountTags} onPress={toggleVisibleTags} />
                    )}
                    {visible && (
                        <View style={[localStyles.tagsGroup, isSubtask && localStyles.tagsGroupSubTasks]}>
                            {children}
                        </View>
                    )}
                </View>
            )}
            {!needSummarize && children}
        </>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        flexGrow: 0,
        paddingLeft: 8,
    },
    containerExpand: {
        paddingBottom: 36,
    },
    containerExpandSubTasks: {
        paddingBottom: 32,
    },
    tagsGroup: {
        position: 'absolute',
        right: 0,
        width: Dimensions.get('screen').width - 24,
        alignContent: 'flex-end',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        top: 36,
    },
    tagsGroupSubTasks: {
        top: 32,
    },
})

export default TaskItemTags
