import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'

import { checkIfSelectedMyDay } from '../../SettingsView/ProjectsSettings/ProjectHelper'
import { getTheme } from '../../../Themes/Themes'
import { Themes } from '../Themes'
import useCollapsibleSidebar from '../Collapsible/UseCollapsibleSidebar'
import useOnHover from '../../../hooks/UseOnHover'
import AssistantAvatar from '../../AdminPanel/Assistants/AssistantAvatar'
import MyDayText from './MyDayText'
import { exitsOpenModals } from '../../ModalsManager/modalsManager'
import ItemShortcut from '../Items/Common/ItemShortcut'
import TasksHelper from '../../TaskListView/Utils/TasksHelper'

export default function MyDayButton() {
    const themeName = useSelector(state => state.loggedUser.themeName)
    const selectedProjectIndex = useSelector(state => state.selectedProjectIndex)
    const defaultAssistantPhoto = useSelector(state => state.defaultAssistant.photoURL50)
    const defaultAssistantId = useSelector(state => state.defaultAssistant.uid)
    const showShortcuts = useSelector(state => state.showShortcuts)
    const showFloatPopup = useSelector(state => state.showFloatPopup)

    const showShortcut = showShortcuts && showFloatPopup === 0 && !exitsOpenModals()

    const { expanded } = useCollapsibleSidebar()

    const highlight = checkIfSelectedMyDay(selectedProjectIndex)

    const { hover, onHover, offHover } = useOnHover(highlight, highlight)

    const theme = getTheme(Themes, themeName, 'CustomSideMenu.AllProjects')

    const onPress = () => {
        TasksHelper.goToMyDay()
    }

    const conatinerStyle = [
        ...(highlight
            ? [localStyles.containerActive, theme.containerActive]
            : [localStyles.containerInactive, theme.containerInactive]),
        !expanded && localStyles.containerCollapsed,
        !highlight && hover && theme.containerActive,
    ]

    return (
        <TouchableOpacity style={conatinerStyle} onPress={onPress} onMouseEnter={onHover} onMouseLeave={offHover}>
            {showShortcut && <ItemShortcut shortcut={'M'} />}
            <View style={localStyles.innerContainer}>
                <AssistantAvatar
                    photoURL={defaultAssistantPhoto}
                    assistantId={defaultAssistantId}
                    size={22}
                    containerStyle={{ marginRight: 10 }}
                />
                {expanded && <MyDayText theme={theme} />}
            </View>
        </TouchableOpacity>
    )
}

const localStyles = StyleSheet.create({
    containerActive: {
        paddingLeft: 24,
        alignItems: 'center',
        flexDirection: 'row',
        height: 56,
        justifyContent: 'space-between',
    },
    containerInactive: {
        paddingLeft: 24,
        opacity: 0.8,
        alignItems: 'center',
        flexDirection: 'row',
        height: 56,
        justifyContent: 'space-between',
    },
    containerCollapsed: {
        paddingLeft: 17,
    },
    innerContainer: {
        alignItems: 'center',
        flexDirection: 'row',
    },
})
