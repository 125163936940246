import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useSelector } from 'react-redux'

import styles, { colors } from '../../styles/global'
import { generateDateHeaderTextInMyDaySection } from '../../../utils/EstimationHelper'

export default function MyDayDateLine() {
    const selectedTasksAmount = useSelector(state => state.myDaySelectedTasks.length)
    const { projectIds, estimations } = useSelector(state => state.myDayEstimationMap)

    const text = generateDateHeaderTextInMyDaySection(projectIds, estimations, selectedTasksAmount)

    return (
        <View style={localStyles.container}>
            <Text style={localStyles.text}>{text}</Text>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        flexDirection: 'row',
        backgroundColor: colors.Grey100,
        borderRadius: 4,
        height: 24,
        alignItems: 'center',
    },
    text: {
        ...styles.overline,
        color: colors.Text02,
        zIndex: 1,
        paddingLeft: 12,
    },
})
