import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy } from 'lodash'

import ProjectsSettingsHeader from './ProjectsSettingsHeader'
import ProjectItem from './ProjectItem'
import ProjectHelper from './ProjectHelper'
import URLsSettings, {
    URL_SETTINGS_PROJECTS,
    URL_SETTINGS_PROJECTS_ARCHIVED,
    URL_SETTINGS_PROJECTS_GUIDE,
} from '../../../URLSystem/Settings/URLsSettings'
import NothingToShow from '../../UIComponents/NothingToShow'
import { setProjectTypeSectionIndex } from '../../../redux/actions'

export const PROJECT_TYPE_ACTIVE = 'active'
export const PROJECT_TYPE_ARCHIVED = 'archived'
export const PROJECT_TYPE_TEMPLATE = 'template'
export const PROJECT_TYPE_GUIDE = 'guide'

export const PROJECT_TYPE_SHARED = 'shared'

export default function ProjectsSettings({}) {
    const dispatch = useDispatch()
    const loggedUser = useSelector(state => state.loggedUser)
    const loggedUserProjects = useSelector(state => state.loggedUserProjects)
    const projectTypeSectionIndex = useSelector(state => state.projectTypeSectionIndex)
    const [filteredProjects, setFilteredProjects] = useState([])

    const type =
        projectTypeSectionIndex === 0
            ? PROJECT_TYPE_ACTIVE
            : projectTypeSectionIndex === 1
            ? PROJECT_TYPE_GUIDE
            : PROJECT_TYPE_ARCHIVED

    useEffect(() => {
        filterProjectsByType()
        writeBrowserURL()
    }, [])

    useEffect(() => {
        filterProjectsByType()
    }, [type, loggedUserProjects, loggedUser.projectIds, loggedUser.archivedProjectIds, loggedUser.templateProjectIds])

    useEffect(() => {
        writeBrowserURL()
    }, [type])

    useEffect(() => {
        return () => {
            dispatch(setProjectTypeSectionIndex(0))
        }
    }, [])

    const writeBrowserURL = () => {
        switch (type) {
            case PROJECT_TYPE_ACTIVE:
                return URLsSettings.push(URL_SETTINGS_PROJECTS)
            case PROJECT_TYPE_ARCHIVED:
                return URLsSettings.push(URL_SETTINGS_PROJECTS_ARCHIVED)
            case PROJECT_TYPE_GUIDE:
                return URLsSettings.push(URL_SETTINGS_PROJECTS_GUIDE)
        }
    }

    const filterProjectsByType = () => {
        let filtered = ProjectHelper.getProjectsByType2(loggedUserProjects, type, loggedUser)
        setFilteredProjects(sortBy(filtered, [item => item.name.toLowerCase()]))
    }

    return (
        <View style={localStyles.container}>
            <ProjectsSettingsHeader amount={filteredProjects.length} type={type} />

            {filteredProjects.length > 0 ? (
                <View style={{ flex: 1 }}>
                    {filteredProjects.map((project, i) => (
                        <ProjectItem key={i} project={project} />
                    ))}
                </View>
            ) : (
                <NothingToShow />
            )}
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
    },
})
