import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { useSelector } from 'react-redux'

import styles, { em2px } from '../../styles/global'
import { checkIfSelectedMyDay } from '../../SettingsView/ProjectsSettings/ProjectHelper'
import { translate } from '../../../i18n/TranslationService'

export default function MyDayText({ theme }) {
    const selectedProjectIndex = useSelector(state => state.selectedProjectIndex)

    return (
        <Text
            style={
                checkIfSelectedMyDay(selectedProjectIndex)
                    ? [localStyles.title, theme.title]
                    : [localStyles.titleInactive, theme.titleInactive]
            }
        >
            {translate('My day')}
        </Text>
    )
}

const localStyles = StyleSheet.create({
    title: {
        ...styles.subtitle1,
    },
    titleInactive: {
        fontFamily: 'Roboto-Regular',
        fontSize: 16,
        lineHeight: 24,
        letterSpacing: em2px(0.02),
    },
})
