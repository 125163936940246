import React from 'react'
import { View, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux'

import MyDayBotLine from './MyDayBotLine/MyDayBotLine'
import MyDayHeader from './MyDayHeader'
import MyDayTasks from './MyDayTasks/MyDayTasks'
import HashtagFiltersView from '../HashtagFilters/HashtagFiltersView'

export default function MyDayView() {
    const smallScreenNavigation = useSelector(state => state.smallScreenNavigation)
    const isMiddleScreen = useSelector(state => state.isMiddleScreen)

    return (
        <View
            style={[
                localStyles.container,
                smallScreenNavigation ? localStyles.containerMobile : isMiddleScreen && localStyles.containerTablet,
            ]}
        >
            <MyDayHeader />
            <HashtagFiltersView />
            <MyDayBotLine />
            <MyDayTasks />
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        marginHorizontal: 104,
    },
    containerMobile: {
        marginHorizontal: 16,
    },
    containerTablet: {
        marginHorizontal: 56,
    },
})
